$font-family: 'IBM Plex Sans';

@media only screen and (max-width: 800px) {

    info {
        display: block;
        text-align: center;
        font-size: 1.1em;
        color: #8594d6;
        margin: 16px 0;
    }


    .mobileonly {
        display:none;
    }

    body {
        font-size: 16pt;
        background-color: #120117;
        font-family: $font-family, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, sans-serif;
        color: white;
    }

    p {
        word-wrap: break-word;
    }
        
    
    .header {
        height: 120px;
        margin: 0 auto;
    }
    
    .title, .svg {
        display: none;
    }
    
    .title_mobile a {
      text-decoration: none;
    }

    .title_mobile {
        display: visible;
        padding-top: 20px;
        font-size: 24px;
        vertical-align: middle;
        padding-left: 20px;
        font-weight: bold;
        text-decoration: none;
        text-align: center;
    }
    
    
    .box {
        max-width: 900px;
        background-color: rgba(0, 0, 0, 0.75);
    }
    
    .content {
        padding: 1em 1em;
        padding-top: 20px;
        padding-bottom: 20px;
        line-height: 1.25em;
    }
    
    
    /* navigation */
    
    nav {
        padding: 0.5em 0;
        max-width: 900px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    
    nav a {
        margin: 0.5em;
        text-decoration: none;
        -webkit-transition-duration: 0.1s;
        -moz-transition-duration: 0.1s;
        -o-transition-duration: 0.1s;
        transition-duration: 0.1s;
    }
    
    .selected {
        padding-bottom: 4px;
        border-bottom: 5px solid #695A89;
    }
    
    nav .mainnav {
        /* margin-left: 20px; */
        font-size:3.75vw;
        line-height: 1.5em;
        font-weight: bold;
    }
    
    nav .social {
        margin-top: -100px;
        padding: 5px;
        float: right;
        margin-right:10px;
    }
    
    @media screen and (max-width: 600px) {
        .topnav.responsive { height: calc(40px * 5); }
        .topnav a:not(.active) {display: none;}
        .topnav a.icon {
            float: right;
            display: block;
        }
        .topnav.responsive {position: relative;}
        .topnav.responsive .icon {
            position: absolute;
            right: 0;
            top: 0;
        }
        .topnav {height: 40px;}
        .topnav a {font-size: 25px;}
        .topnav.responsive a {
            font-size: 25px;
            float: none;
            display: block;
            text-align: left;
        }
    }

    /* links */
    a:link {color: white;}
    a:visited {color: white;}
    a:hover {color: #9C9C9C;}
    a:active {color: #6F6F6F;}
    
    .selected a:link {color: #000000;}
    .selected a:visited {color: #000000;}
    .selected a:hover {color: #000000;}
    .selected a:active {color: #000000;}
    
    
    /* footer */
    
    .footer {
        background-color: black;
        margin-top: 12px;
        margin-bottom: -22px;
        margin: 0 auto;
        padding-bottom: 20px;
        /* padding-left: 20px; */
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-width: 0;
        color: gray;
        font-size: 10pt;
        max-width:900px;
        text-align: center;
    
    }
    
    .footer a:link {color: gray;}
    .footer a:visited {color: gray;}
    .footer a:hover {color: #9C9C9C;}
    .footer a:active {color: #6F6F6F;}
    
    small {
      font-size:14pt;
      text-decoration: none;
    }
    
    
    table { max-width: 100%; min-width: 66%; background-color: #170914; padding: 0em !important;}
    table, th { 
        border: 2px solid #5c1d57; 
        border-radius: 10px;
    }
    th {
        font-size: 16pt;
        border: 0;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        background-color: #3B1232;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    td.icon {
        text-align: center;
        vertical-align: middle;
        font-size:16pt;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    td.head {
        color: #D054B5;
        text-align: center;
        font-weight: bold;
        border-bottom: 2px solid #D054B5;
    }

    tr:nth-child(even) {
        background-color: #0E040C;
    }

    td span.subtitle {
        font-size: 10pt;
        color: rgb(133, 118, 133);
        font-style: italic;
        line-height: 0;
    }
    
    
    
    hr {
        border: 0;
        border-bottom: 2px dashed #bbb;
        margin: 32px 0px;
    }
    
    h1 {
        font-size: 6vw;
        text-decoration: none;
        text-shadow: 1px 1px 2px black;
        font-weight: 500;
        margin-bottom: 5px;
        line-height: 1;
    }
    
    h2 {
        margin-bottom: 5px;
        color: #668;
        font-weight: 600;
        font-size: 20px;
    }
    
    h3 {
        font-style: oblique;
        font-size: 16pt;
        margin-bottom: 3px;
        margin-top: 12px;
        font-weight: normal;
        color: #FFF;
        letter-spacing: 2px;
    }
    


    
    /* leftovers from 3.0 */
    .mininav { font-size:24pt; text-decoration: none; text-shadow: 1px 1px 2px black; }
    .mininav a { text-decoration: none; }
    
    .new {
        color: black;
        background-color: white;
        font-size: 10pt;
        text-transform: uppercase;
        border: 2px white solid;
        border-radius: 3px;
        margin-left: 2px;
        padding-left: 3px;
        padding-right: 3px;
        font-weight: 600;
        text-shadow: none;
    }
    
    
    /*discord timeline page*/
    .timeline table, .timeline th { border: 1px solid white; text-align: left; padding: 10px; }
    .timeline td { padding:10px 10px; text-align:left; }
    
    
    /* responsive guff - WORK IN PROGRESS */

    .about img {
        width: 100%;
        height: auto;
    }

    .about {
        line-height: 1.5em;
    }

    .subtle {
    color: #6D6D6D;
    font-style: italic;
    }

    .subtle a {
        color: #6D6D6D;
    }

    .warn {font-size: 12pt;}


    /* for Directory.html and whatnot */
    .headlink { 
        font-size:24pt; text-decoration: none; line-height: 1.25em; 
        a { text-decoration: none; }
    }
    .headicon {
        font-size:36pt;
        float:left;
        padding-right: 12px;
        padding-top: 6px;
        padding-bottom: 13px;
    }
    .noline a { text-decoration: none; }
    .headtainer {max-width:800px;}

    .mainnav .icon, .mainnav .close, .socialname { display: none; }


    

    .clicktitle {
        text-align: center;
        font-size: 1.5em;
    }

   
    


}








@media screen and (max-width:550px) {

    .flextainer {
        display: block;
        flex-shrink: 0;
        
    }

    .header {
        height: 0px;
        margin: 0 auto;
    }

    .svglogo {
        height:60px;
        width:60px;
    }

    .title_mobile {
        display: visible;
        padding-top: 10px;
        font-size: 24px;
        vertical-align: middle;
        padding-left: 20px;
        font-weight: bold;
        text-decoration: none;
        text-align: left;
    }
    

    .socialname {display: inline;}

    /* for Directory.html and whatnot */
    .headlink { font-size:7vw; text-decoration: none; line-height: 1.5em; float:center; text-align: center;}
    .headlink a { text-decoration: none; }
    .headicon {
        font-size:20vw;
        float:none;
        padding-right: 12px;
        padding-top: 6px;
        padding-bottom: 13px;
        text-align: center;
        }
    .noline a { text-decoration: none; }
    .headtainer {margin-top: 12px;
        max-width:800px;
        border-bottom: 1px solid #bbb;
        text-align: center;
    }

    /* for fanpages */

    .mobileonly {
        display:block;
        font-size: 5vw;
        text-align: center;
        line-height: 1.25em;
        background-color: #612624;
        padding: 8px 6px;
        border-radius: 10px;
    }





    body {font-size:13pt !important; }
    /*nav .mainnav {font-size:6vw;} */

    nav .social {
        margin: 0;
        padding: 0;
        float: none;
        display: inline;
    }


    nav {padding: 0;}

    nav .mainnav {
        overflow: hidden;
        transition: height .5s ease;
        line-height: 30px;
    }

    nav .mainnav a {
        float: left;
        display: block;
        text-align: center;
        /* padding: 5px 14px; */
        text-decoration: none;
        font-size: 24px;
        transition: color .2s ease;
    }

  

    
    .mainnav a:not(.active) {display: none;}
    .mainnav a.icon {
        float: right;
        display: block;
        padding-top: 25px;
        padding-bottom: 25px;
        padding-right: 15px;
        padding-left: 15px;
        margin: 0em;
        background-color: #120117;
    }

    .mainnav .icon {
        background-color: black;
    }
    .mainnav {height: 80px;background-color: #261039;}
    .mainnav a {font-size: 25px;}

    .mainnav.responsive { height: calc(40px * 12); position: relative;}
    .mainnav.responsive .icon {
        display:none;
    }

    form, label {
        max-width:50vw !important;
    }

    .mainnav.responsive .close {
        position: absolute;
        right: 0;
        top: 0;
    }
    .mainnav.responsive a {
        font-size: 25px;
        float: none;
        display: block;
        text-align: left;
    }
}