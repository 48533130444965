$background: url("/img/purpdust.png");
$scrollbar-front: #50197E;
$scrollbar-track: #120118;
$font-family: 'IBM Plex Sans';


html {
    scrollbar-color: $scrollbar-front $scrollbar-track;
}

/* Chrome/WebKit/IE custom scrollbars */
body {
    scrollbar-face-color:		$scrollbar-front;
    scrollbar-track-color:		$scrollbar-track;
    scrollbar-arrow-color:		#FFFFF0;
    scrollbar-highlight-color:	#924BCE;
    scrollbar-3dlight-color:	#924BCE;
    scrollbar-shadow-color:	    #50197E;
    scrollbar-darkshadow-color:	#312d7d;
}

::-webkit-scrollbar {
    width:	1em;
    height:	1em;
}
::-webkit-scrollbar-track	{
    background-color: $scrollbar-track;
}
::-webkit-scrollbar-thumb	{
    background-color:	$scrollbar-front;
    background-size:	contain;
    background-repeat:	no-repeat;
    background-position:	center;
}

/* End of scrollbar code */


.warn
{
    font-size: 10pt;
    color: salmon;
    font-style: italic;
}

.hidden {color: black !important;}

summary {
    cursor: pointer;
    text-align: center;
    font-size: 14pt;
    background-color: #44375d;
    padding: 6px;
    border-radius: 5px;
    color:#dedcff;
    font-style: italic;
    transition: 0.1s ease;
}

summary:focus {outline: none;} /* so that chromium-based browsers don't create an outline when clicked */

summary:hover {
    filter: brightness(0.75);
    transition: 0.1s ease;
}

summary:active {
    filter:brightness(0.5);
    transition: 0.05s ease;
}

/* TODO: get this to work somehow
details open ::marker {
    content: '▶';
    transform: rotate(90deg);
    transition: 0.5s ease;
} */

details {
    transition: 5s ease;
}



@media only screen and (max-width: 870px) {
    nav .mainnav {
        margin-left: 0px !important; 
    }
}

@media only screen and (min-width: 800px) {
    body {
        background-image: $background;
        background-color: #000000;
        background-repeat: repeat;
        font-family: $font-family, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, sans-serif;
        font-size: 12pt;
        color: white;
    }

    .mobileonly {
        display: none;
    }

    body > * {flex: 1 1 100%;}

    .header {
        /*border: 2px solid #422798;
        box-shadow: 2px 4px 4px rgba(0,0,0,.25);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px; */
        max-width: 900px;
        height: 160px;
        margin: 0 auto;
        background-image: url("../img/header.png");
        background-color: #000000;
        background-size: cover;
        border: 2px solid #691D6A;
        box-shadow: 4px 6px 4px rgba(0,0,0,.5);
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        border-top-width: 0;
    }

    .title {
        padding-top: 18px;
        font-size: 48px;
        vertical-align: middle;
        text-align: center;
        font-weight: bold;
        text-decoration: none;
        a {
            text-decoration: none;
            cursor: pointer;
        }
    }


    .title_mobile {display: none;}

    .box {
        max-width: 900px;
        border: 2px solid #622e8ebd;
        box-shadow: 4px 6px 4px rgba(0,0,0,.5);
        background-color: rgba(0, 0, 0, 0.5);
        margin: 5px auto 2em;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .content {
        padding: 1em 2em;
        padding-top: 20px;
        padding-bottom: 20px;
        line-height: 1.25em;
    }


    /* navigation */

    .mainnav .icon, .mainnav .close, .socialname { display: none; }

    nav {
        margin-top: 0.5em;
        padding: 0.5em 0;
        max-width: 900px;
        width: 100%;
        font-size: 12pt;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        a {
            margin: 0.75em;
            text-decoration: none;
            -webkit-transition-duration: 0.1s;
            -moz-transition-duration: 0.1s;
            -o-transition-duration: 0.1s;
            transition-duration: 0.1s;
        }
        .social {
            padding: 0;
            float: right;
            margin-right:10px;
        }
    }


    nav .selected {
        padding-bottom: 4px;
        border-bottom: 5px solid #695A89; 
    }

    nav .mainnav { /*only use this if you have social links enabled */
        margin-left: 96px; 
    }




    /* links */
    a:link {color: white;}
    a:visited {color: white;}
    a:hover {color: #9C9C9C;}
    a:active {color: #6F6F6F;}

    nav .selected a:link {color: #000000;}
    nav .selected a:visited {color: #000000;}
    nav .selected a:hover {color: #000000;}
    nav .selected a:active {color: #000000;}


    /* footer */

    .footer {
        background-color: black;
        margin-top: 12px;
        margin-bottom: -22px;
        margin: 0 auto;
        padding-bottom: 20px;
        /* padding-left: 20px; */
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-width: 0;
        color: gray;
        font-size: 10pt;
        max-width:900px;
        text-align: center;

        a:link {color: gray;}
        a:visited {color: gray;}
        a:hover {color: #9C9C9C;}
        a:active {color: #6F6F6F;}
    }

    small {
    font-size:8pt;
    text-decoration: none;
    }

    table { max-width: 100%; min-width: 66%; background-color: #170914; }
    table, th { 
        border: 2px solid #5c1d57; 
        border-radius: 10px;
    }
    th {
        font-size: 16pt;
        border: 0;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        background-color: #3B1232;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    td.icon {
        text-align: center;
        vertical-align: middle;
        font-size:16pt;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    td.head {
        color: #D054B5;
        text-align: center;
        font-weight: bold;
        border-bottom: 2px solid #D054B5;
    }

    tr:nth-child(even) {
        background-color: #0E040C;
    }

    td span.subtitle {
        font-size: 10pt;
        color: rgb(133, 118, 133);
        font-style: italic;
        line-height: 0;
    }

    /* for Directory.html and whatnot */
    .headlink { 
        font-size:24pt; text-decoration: none; line-height: 1.25em; 
        a { text-decoration: none; }
    }
    .headicon {
        font-size:36pt;
        float:left;
        padding-right: 12px;
        padding-top: 6px;
        padding-bottom: 13px;
    }
    .noline a { text-decoration: none; }
    .headtainer {width:800px;}

    hr {
        border: 0;
        border-bottom: 2px dashed #bbb;
        margin: 32px 0px;
    }

    h1 {
        font-size:24pt;
        text-decoration: none;
        text-shadow: 1px 1px 2px black;
        font-weight: 500;
        margin-bottom: 5px;
    }

    h2 {
        margin-bottom: 5px;
        color: #d3adf2;
        font-weight: 600;
        font-size: 20px;
    }

    h3 {
        font-style: oblique;
        font-size: 16px;
        margin-bottom: 3px;
        margin-top: 12px;
        font-weight: normal;
        color: #FFF;
        letter-spacing: 2px;
    }

    .listlink {
        font-weight: bold;
    }

    .listlink a:link { color: #ab6ebb !important; }
    .listlink a:hover { color: #c292ce !important; }


    info {
        display: block;
        text-align: center;
        font-size: 1.2em;
        color: #8594d6;
        margin: 16px 0;
        line-height: 1.25em;
    }

    
    /* SVG linking and animation */

    .svg {
        transition: 0.10s;
        -webkit-transition: 0.10s;
        -moz-transition: 0.10s;
        -ms-transition: 0.10s;
        -o-transition: 0.10s;
    }


    .svg:hover {
        /*codestolenfromemma(sorryemma)*/transform: scale(1.125);
        -webkit-transform: scale(1.125);
        -moz-transform: scale(1.125);
        -ms-transform: scale(1.125);
        -o-transform: scale(1.125);
        transition: 0.25s;
        -webkit-transition: 0.25s;
        -moz-transition: 0.25s;
        -ms-transition: 0.25s;
        -o-transition: 0.25s;
    }

    .svg:active {
        transform: scale(0.9);
        -webkit-transform: scale(0.9);
        -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
        -o-transform: scale(0.9);
        transition: 0.1s;
        -webkit-transition: 0.1s;
        -moz-transition: 0.1s;
        -ms-transition: 0.1s;
        -o-transition: 0.1s;
        filter: invert(27%);
        -webkit-filter: invert(27%);
    }

    a.svg {
    position: relative;
    display: inline-block;
    cursor: auto;
    }

    a.svg:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: default;
    }

    .svg:hover path {
        fill: black;
        transition: all ease 0.3s;
    }
    
    .svg path {
        transition: all ease 0.3s;
    }

    /* leftovers from 3.0 */
    .mininav {
        font-size:24pt; text-decoration: none; text-shadow: 1px 1px 2px black; 
        a { text-decoration: none; }
    }

    .new {
        color: black;
        background-color: white;
        font-size: 10pt;
        text-transform: uppercase;
        border: 2px white solid;
        border-radius: 3px;
        margin-left: 2px;
        padding-left: 3px;
        padding-right: 3px;
        font-weight: 600;
        text-shadow: none;
    }


    /*discord timeline page*/
    .timeline table, .timeline th { border: 1px solid white; text-align: left; padding: 10px; }
    .timeline td { padding:10px 10px; text-align:left; }

    .about {
        display: table-cell;
        line-height: 1.5em;
        img {
            float: left;
            margin-right: 18px;
        }
    }

    .subtle {
        color: #6D6D6D;
        font-style: italic;
        font-size: 10pt;
        a {
            color: #6D6D6D;
        }
    }

    .ring
    {
        max-width:700px;
        margin: 0 auto;
    }

}