@keyframes hover {
    0% {transform: translateY(-10px);}
    100% {transform: translateY(10px);}
}


.censored {
    background-color: #1f1e1e;
    color: transparent;
}

.black {
    background-color: black;

    .fstitle {
        background-image: linear-gradient(90deg, #241e27, rgba(0, 0, 0, 0))
    }

    .fstitle a {
        color: black !important;
        background-color: black;
    }

    .fsnumber {
        background-color: black;
        color: white;
    }

    .fsthumb, .fsthumb2 {
        border-color: black;
    }

    color: gray;
    .fsbutton {
        font-weight: normal;
        background-color: black;
    }

    a {cursor: not-allowed;}
}

@media only screen and (min-width: 550px) {
    
    .flextainer {
        display: flex;
        flex-shrink: 0;
        
    }

    .rounded {
        border-radius: 20px;
        width: 100% !important;
        padding-bottom: 14px;
        transition: 0.4s ease;
        margin: 36px 0;
    }

    .rounded:hover {
        filter: brightness(1.25);
        transition: 0.1s ease;
    }

    .clicktitle {
        text-align: center;
        font-size: 1.25em;
    }


    .arrowed {
        text-transform: uppercase;
        letter-spacing: 8px;
    }

    .arrowed p::before, .arrowed p::after {
        content: '▼';
        animation: hover 1.5s ease-in-out alternate infinite;
        display: inline-block; 
        margin: 0 16px;
    }

    .nonarrowed {
        display: none;
    }
    

    .fsinfo {
        float: right;
    }

    .fsinfo p {
        padding-left: 18px;
        padding-right: 18px;
    }


    .fsthumb {
        float: left;
        align-self: flex-start;
        max-width: 150px;
        max-height: 150px;
        transition: all 0.2s ease-in-out;
        border: 1px solid white;
        border-top-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .fsthumb2 {
        max-width: 150px;
        max-height: 150px;
        border-top-left-radius: 20px;
        border-bottom-right-radius: 20px;
        transition: all 0.2s ease-in-out;
    }


    .fsthumb:hover {
        transition: all 0.2s ease-in-out;
        transform: scale(1.2);
        filter: hue-rotate(20deg);
        border: 1px solid rgb(148, 228, 112);
        border-radius: 20px;
    }

    .fsthumb2:hover {border-radius: 20px;}

    .fsthumb:active {
        transition: .1s ease;
        transform: scale(0.9);
        filter: brightness(0.5);
    }

    .fstitle {
        font-size: 24pt;
        font-weight: bold;
        vertical-align: top;
        line-height: 1.15;
        transition: 0.2s ease;
    }

    .fstitle small {
        font-size: 12pt;
        vertical-align: middle;
    }

    .fstitle a small {
        color: rgba(255, 255, 255, 0.322);
    }

    .fstitle a {transition: 0.2s ease;}

    .fstitle a:hover {
        color: rgba(255, 255, 255, 0.479);
        transition: 0.2s ease;
    }
    

    .fsnumber {
        background: white;
        color: black;
        padding: 2px 4px;
        font-weight: normal;
    }

    .fsbutton {
        display: block;
        align-self: right;
        padding: 8px 16px;
        font-size: 16pt;
        font-weight: 600;
        letter-spacing: -0.5px;
        float: right;
        margin: -6px 16px 16px 0;
        transition: 0.15s ease-in-out;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.4);
        border-radius: 5px;

    }

    .fsbutton:hover {
        transition: 0.15s ease-in-out;
        transform: scale(1.1);
        filter: brightness(1.25);
    }
    
    .fsbutton:active {
        transition: 0.1s ease-in-out;
        transform: scale(0.95);
        filter: brightness(0.8);
    }

  


}

@media only screen and (max-width: 800px) {

    @media only screen and (min-width:551px) {
        
        .fsinfo p {
            font-size: 2.75vw;
            line-height: 1.25em;
        }

        .fsthumb:active {
            transform: scale(0.75);
        }

        .fsnumber {
            padding: 8px;
        }
        
        .rounded {
            margin: 48px 0;
        }
        
        .fsbutton {
            font-size: 24pt;
        }

        .rounded:hover {
            filter: none;
        }

        .fstitle {
            line-height: 1.5em;
        }

        .arrowed, .arrowed p::before, .arrowed p::after {
            letter-spacing:normal;
            text-transform:normal;
        }

        

    }

}


@media screen and (max-width:550px) {

.arrowed, .arrowed p::before, .arrowed p::after {
    display: none; 
}

.nonarrowed {
    display: block;
    font-size: 1.5em;
}

.rounded {
    border-radius: 10px;
    padding-bottom: 18px;
    margin: 36px 0;
    border-bottom: 0px !important;
}

.rounded p {
    text-align: left !important;
    padding: 0 8px;
    
}

.fsthumb {
    margin: auto;
}

.fsthumb2 {
    max-width: 125px;
    max-height: 125px;
    border-radius: 20px;
    transition: 0.2s ease;
    border: 1px solid white;
    border-radius: 20px;
    margin-top: -20px;
}

.fsnumber {
    visibility: hidden;
    width: 0;
    height: 0;
    display: inline-block;
}

.fstitle {
    font-size: 8vw;
    font-weight: bold;
    vertical-align: top;
    padding: 8px 8px 8px 0px;
    transition: 0.2s ease;
    line-height: normal;
}


.fsbutton {
    display: inline-block;
    padding: 8px 16px;
    font-size: 7.5vw;
    font-weight: 600;
    letter-spacing: -0.5px;
    transition: 0.05s ease;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.4);
    border-radius: 5px;

}

.fsbutton:active {
    transition: 0.05s ease;
    transform: scale(0.75);
    filter: brightness(0.8);
}


}
